.rbc-agenda-view {
  background-color: #ffffff;
}

.rbc-time-view {
  background-color: #ffffff;
}

.rbc-month-view {
  background-color: #ffffff;
}

.rbc-agenda-event-cell span {
  width: 50%;
}

.rbc-agenda-event-cell-date {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
}

.rbc-agenda-event-cell-date .day {
  font-size: 1.3rem;
  font-weight: 600;
  color: #000000;
}

.rbc-time-view {
  .rbc-label {
    display: none;
  }
  .rbc-allday-cell {
    height: calc(100vh - 98px);
    max-height: unset;
  }
  .rbc-time-content {
    display: none;
  }
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th:nth-child(2) {
  display: none !important;
}

.rbc-agenda-time-cell {
  display: none;
}

.rbc-agenda-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 98px);
  font-size: "1.5rem";
}
