@import "~react-agenda/build/styles.css";
@import "~react-datetime/css/react-datetime.css";

@media print {
  @page {
    size: auto;
    margin: 0;
    orientation: landscape;
    padding: 0;
  }
  .no-print,
  .no-print * {
    display: none !important;
    overflow: none !important;
    overflow-y: none !important;
    overflow-x: none !important;
  }
  .css-18njce8 {
    display: none !important;
  }

  .css-10mg1vw-MuiStepper-root {
    display: none !important;
  }
  .css-1187icl {
    display: none !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar > div::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar > div {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.MuiTextField-root {
  margin-top: 1rem !important;
}

.MuiOutlinedInput-root {
  height: 2.758rem !important;
}

.react-datepicker__close-icon {
  top: 10px !important;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #f3f3f3 !important;
  color: #707070 !important;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 25px !important;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.css-1dur2uz-MuiPaper-root-MuiDialog-paper {
  overflow-y: unset !important;
}

.filterInputLabelPlan {
  margin-left: 0px !important;
}
.filterInputPlan {
  width: 300px !important;
}

/* .css-zwzoq3-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
  width: 30px !important;
  height: 30px !important;
}

.css-zwzoq3-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
  width: 30px !important;
  height: 30px !important;
}

.css-zwzoq3-MuiSvgIcon-root-MuiStepIcon-root{
  width: 30px !important;
  height: 30px !important;
} */

.MuiStepIcon-root {
  width: 30px !important;
  height: 30px !important;
}
